import '../sass/style.sass'

import $ from 'jquery'
import 'slick-carousel'

$(document).ready(() => {
    $('.types .slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        dots: true,
        prevArrow:
            "<div type='prev-button' class='slick-prev pull-left'><img loading='lazy' src='/app/themes/chatterton-tuition/img/assets/previous.png' alt='prev'></i></div>",
        nextArrow:
            "<div type='next-button' class='slick-next pull-right'><img loading='lazy' src='/app/themes/chatterton-tuition/img/assets/next.png' alt='next'></div>",
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    })

    $('.our-tutors .slider').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        arrows: true,
        dots: true,
        prevArrow:
            "<div type='prev-button' class='slick-prev pull-left'><img loading='lazy' src='/app/themes/chatterton-tuition/img/assets/previous.png' alt='prev'></i></div>",
        nextArrow:
            "<div type='next-button' class='slick-next pull-right'><img loading='lazy' src='/app/themes/chatterton-tuition/img/assets/next.png' alt='next'></div>",
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    })

    $('.subjects .slider').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        dots: true,
        prevArrow:
            "<div type='prev-button' class='slick-prev pull-left'><img loading='lazy' src='/app/themes/chatterton-tuition/img/assets/previous.png' alt='prev'></i></div>",
        nextArrow:
            "<div type='next-button' class='slick-next pull-right'><img loading='lazy' src='/app/themes/chatterton-tuition/img/assets/next.png' alt='next'></div>",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    })

    $('.testimonials .slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        prevArrow:
            "<div type='prev-button' class='slick-prev pull-left'><img loading='lazy' src='/app/themes/chatterton-tuition/img/assets/previous.png' alt='prev'></i></div>",
        nextArrow:
            "<div type='next-button' class='slick-next pull-right'><img loading='lazy' src='/app/themes/chatterton-tuition/img/assets/next.png' alt='next'></div>",
        infinite: true,
        autoplaySpeed: 5000,
    })

    $('.accordion-item .button-wrapper').on('click', function () {
        const current = $(this)

        // Close all other open items
        $('.accordion-item .button-wrapper').each(function () {
            if ($(this).next().is(':visible') && !$(this).is(current)) {
                $(this).next().slideUp()
                $(this)
                    .children()
                    .find('h2')
                    .text((i, text) => {
                        return text == '+' ? '-' : '+'
                    })
            }
        })

        $(this).next().slideToggle()
        $(this)
            .children()
            .find('h2')
            .text((i, text) => {
                return text == '+' ? '-' : '+'
            })
    })
})

new Vue({
    el: '#navigation',
    data: {
        mobileMenuActive: false,
    },
})
